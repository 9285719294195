import { useAuthContext } from "auth/context";
import withPageAuthRequired from "auth/withPageAuthRequired";
import { useExtensionController } from "client/app/extension/__components/ExtensionControllerProvider";
import Layout from "components/Layout";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Permission } from "shared/auth/permissions";
import inIframe from "utils/in-iframe";

function HomePageWrapper() {
  const { sendMessageToParent } = useExtensionController();
  const { checkRolePermissions, loading } = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    if (!loading) {
      const accessToGuidesPage = checkRolePermissions(Permission.GUIDE.READ);
      if (accessToGuidesPage) {
        router.push("/candidates");
      } else {
        router.push("/account");
      }
    }
  }, [checkRolePermissions, loading, router]);

  if (inIframe()) {
    sendMessageToParent({
      command: "reload",
    });
    return null;
  }

  return null;
}

export default withPageAuthRequired(HomePageWrapper, {
  getLayout: (page) => <Layout>{page}</Layout>,
});
